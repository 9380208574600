* {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  margin-inline: auto;

  ::selection {
    background-color: $color-primary;
    color: $color-on-primary;
  }
}

main {
  flex-grow: 1;
  display: grid;
  //align-content: space-evenly;
  gap: ($spacing * 2) $spacing;
  grid-template: auto / [fullbleed-start] minmax($spacing * 0.5, auto) [content-start] minmax(250px, 1024px) [content-end] minmax($spacing * 0.5, auto) [fullbleed-end];

  @media (min-width: 600px) {
    gap: ($spacing * 4) ($spacing * 2);
    grid-template: auto / [fullbleed-start] minmax($spacing, auto) [content-start] minmax(250px, 1024px) [content-end] minmax($spacing, auto) [fullbleed-end];
  }

  > *:not(.fullbleed) {
    grid-column: content;
  }

  > .fullbleed {
    grid-column: fullbleed;
    display: grid !important;
    grid-template-columns: subgrid;

    > * {
      grid-column: content;
    }
  }
}
