section {
  padding: ($spacing * 3) ($spacing * 3) ($spacing * 3);
  display: flex;
  flex-direction: column;
  gap: $spacing ($spacing * 1);

  &.bg-primary {
    background-color: $color-primary;
    color: $color-on-primary;
  }

  &.bg-clouds {
    text-shadow: 0.0625em 0.0625em 0.0625em rgba(var(--base), 0.5);
    background: $color-primary url('../svg/clouds-static.svg') center / cover no-repeat;
    position: relative;

    &:before {
      @include box-shadow;

      z-index: -1;
      position: absolute;
      inset: $spacing;
      margin: 0 auto;
      max-width: 1024px;
      content: '';
      background: var(--base);
      opacity: 0.75;
    }
  }

  &.bg-clouds-animated {
    background-image: url('../svg/clouds.svg');
  }

  &#intro {
    padding: ($spacing * 2) 0 0;
    text-align: center;
    display: grid;
    justify-items: center;
    align-items: center;
    gap: $spacing ($spacing * 2);
    grid-template-areas:
        "badge"
        "title"
        "headshot"
        "highlighted";

    @media (min-width: 768px) {
      grid-template-areas:
        "badge title       headshot"
        "badge highlighted headshot";
      grid-template-columns: 1fr 3fr 1fr;
    }

    @media (min-width: 1024px) {
      grid-template-columns: 1fr 4fr 1fr;
    }

    @media (min-width: 1280px) {
      grid-template-columns: 1fr 5fr 1fr;
    }

    .badge,
    .headshot {
      @include box-shadow;

      max-width: 300px;
      width: 100%;
    }

    .badge {
      grid-area: badge;
      aspect-ratio: 1;
      border-radius: 50%;
    }

    .headshot {
      grid-area: headshot;
      margin: 0;
      aspect-ratio: 1;
      border-radius: 50%;
      position: relative;
      overflow: clip;

      img {
        width: 100%;
        border-radius: inherit;
      }

      @include zing-flick;
    }

    p {
      grid-area: title;
      place-self: end center;
      font-size: 1.5rem;
      text-wrap: balance;
    }

    ul.highlighted {
      grid-area: highlighted;
      place-self: start center;
      margin: 0;
      padding: 0;
      justify-content: start;
      display: flex;
      flex-direction: column;
      gap: $spacing * 0.5;

      li {
        display: flex;
        align-items: center;
        text-align: start;
        text-wrap: pretty;
        gap: $spacing;

        svg {
          width: 1.25em;
        }
      }
    }
  }

  &#skills {
    display: grid;
    place-content: center;

    &:before {
      z-index: 0;
    }

    > * {
      z-index: 1;
    }

    ul {
      text-align: center;
      text-wrap: balance;

      li {
        margin-block: 0.125em;
        display: inline-block;
        background-color: $color-primary;
        color: $color-on-primary;
        text-align: center;
        border-radius: $border-radius;
        padding: $spacing * 0.5 $spacing;
      }
    }
  }

  &.examples {
    div.images {
      $gradient-colours: transparent 0 40%, $color-primary 40.2% 41%, transparent 41.2% 45%, $color-primary 45.2%, transparent 55%;
      border-image: linear-gradient(175deg, $gradient-colours) fill 0 / / 0 100vw;

      &:nth-child(even) {
        border-image: linear-gradient(185deg, $gradient-colours) fill 0 / / 0 100vw;
      }
    }
  }
}
