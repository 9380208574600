* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin-block: 0;
}

video {
  max-width: min(80dvw, 1024px);
  max-height: min(80dvh, 768px);
  aspect-ratio: var(--aspect-ratio, 16 / 9);
  margin-inline: auto;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
