html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

h1, h2, h3, h4, h5, h6 {
  font-family: Recursive, sans-serif;
  font-variation-settings: "CASL" 0.6;
  margin: 0;
}

h3 {
  font-size: 1.66rem;
}

h4 {
  font-size: 1.33rem;
}

::selection {
  color: $color-primary;
  background-color: $color-on-primary;
}
