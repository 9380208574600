@import '01-config/variables';
@import '01-config/mixins';

@import '02-base/reset';
@import '02-base/typography';
@import '02-base/layout';
@import '02-base/darkmode';
@import '02-base/animations';

//@import '03-objects/richtext';

@import '04-components/header';
@import '04-components/footer';
@import '04-components/section';

@import '05-utilities/utils';

@import url('https://fonts.googleapis.com/css2?family=Recursive:wght,CASL,CRSV,MONO@300..1000,0..1,0,1&display=swap');

a.button {
  display: inline-block;
  border-radius: $border-radius * 2;
  background: $color-primary;
  color: $color-on-primary;
  text-decoration: none;
  white-space: nowrap;
  padding: $spacing * 0.5;
  transition: scale 300ms, box-shadow 600ms;
  box-shadow: 0 0 0 $color-pop;

  &:hover {
    scale: 1.1;
    box-shadow: 0 0 0 5px $color-pop;
  }
}

#promo {
  @include box-shadow;
  @include zing-flick;

  position: sticky;
  top: 85px;
  z-index: 9;
  display: flex;
  gap: $spacing;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  backdrop-filter: blur(10px);
  border-radius: 999em;
  //margin: ($spacing * 0.5) 0 ($spacing * 2);
  padding: ($spacing * 2) ($spacing * 2.5);

  @media (min-width: 600px) {
    top: 105px;
  }

  &:before {
    border-radius: 999em;
  }

  p {
    text-wrap: balance;
    font-weight: 800;

    &:first-of-type {
      font-size: larger;
    }

    &:last-of-type {
      font-size: smaller;
    }
  }

  .buttons {
    $size: 3em;

    text-wrap: balance;

    a {
      width: $size;
      height: $size;
      display: inline-grid;
      place-content: center;
      margin: $size * 0.0625;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

hr {
  margin-block: $spacing * 2;
  width: 100%;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, $color-pop, $color-primary, $color-pop);
}

.images {
  $min-width: 240px;
  $gap: 10px;

  container-type: inline-size;
  margin-block: $spacing;
  display: grid;
  gap: $spacing;
  grid-template: auto / repeat(auto-fit, minmax($min-width, 1fr));
  place-items: center;

  > * {
    aspect-ratio: 4 / 3;
    //border-radius: $border-radius;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

    @container (#{($min-width * 2) + $gap} <= width <= #{($min-width * 3) + ($gap * 2)}) {
      &:nth-child(odd):last-child {
        grid-column: span 2;
      }
    }
  }

  img {
    object-fit: cover;

    @include zing-flick;
  }
}
