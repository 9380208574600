header,
footer {
  @include box-shadow;

  background-color: #000000;
  color: #ffffff;
  text-align: center;
}

@mixin cloud-text {
  background: black;

  h1 {
    background: #ffffff url("../svg/clouds-static.svg");
    background-size: contain;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
}

@mixin cloud-bg {
  background: $color-primary url('../svg/clouds-static.svg') center / cover no-repeat;

  h1 {
    text-shadow: 0 0 0.125em #ffffff;
    background: none;
    background-clip: initial;
    color: #000000;
  }
}

header {
  position: sticky;
  top: 0;
  z-index: 10;
  padding: ($spacing * 2) ($spacing * 3);
  display: flex;
  flex-direction: column;
  gap: $spacing * 0.5;
  // @todo gradual blur stripes

  h1 {
    font-size: 2.5rem;
  }

  @media (prefers-color-scheme: dark) {
    @include cloud-text;
  }

  @media (prefers-color-scheme: light) {
    @include cloud-bg;
  }

  :root:has(#theme [value="☀️"]:checked) & {
    @include cloud-bg;
  }

  @media (min-width: 600px) {
    h1 {
      font-size: 3.5rem;
    }

    h2 {
      font-size: 1.5rem;
    }
  }
}
