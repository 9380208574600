:root {
  --base: #ffffff;
  --text: #333333;
}

@media (prefers-color-scheme: dark) {
  :root {
    --base: #333333;
    --text: #ffffff;
  }
}

html {
  color-scheme: dark light;
}

:root:has(#theme [value="☀️"]:checked) {
  color-scheme: light;
  --base: #ffffff;
  --text: #333333;
}

:root:has(#theme [value="🌑"]:checked) {
  color-scheme: dark;
  --base: #333333;
  --text: #ffffff;
}

body {
  color: var(--text);
  fill: var(--text);
  background-color: var(--base);
}

#theme {
  display: none;
  position: absolute;
  inset: $spacing $spacing auto auto;

  @media (min-width: 600px) {
    display: block;
  }

  label {
    background: transparent;

    &:has(input:checked) {
      text-shadow: 0 0 0.25em $color-pop;
    }

    input {
      display: none;
    }
  }
}
