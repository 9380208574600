@import "https://fonts.googleapis.com/css2?family=Recursive:wght,CASL,CRSV,MONO@300..1000,0..1,0,1&display=swap";
body {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}

p {
  margin-block: 0;
}

video {
  aspect-ratio: var(--aspect-ratio, 16 / 9);
  max-width: min(80dvw, 1024px);
  max-height: min(80dvh, 768px);
  margin-inline: auto;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

svg {
  fill: currentColor;
  width: 100%;
  height: 100%;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

h1, h2, h3, h4, h5, h6 {
  font-variation-settings: "CASL" .6;
  margin: 0;
  font-family: Recursive, sans-serif;
}

h3 {
  font-size: 1.66rem;
}

h4 {
  font-size: 1.33rem;
}

::selection {
  color: #0698f2;
  background-color: #fff;
}

* {
  box-sizing: border-box;
}

body {
  flex-direction: column;
  min-height: 100dvh;
  margin-inline: auto;
  display: flex;
}

body ::selection {
  color: #fff;
  background-color: #0698f2;
}

main {
  flex-grow: 1;
  grid-template: auto / [fullbleed-start] minmax(.5rem, auto)[content-start] minmax(250px, 1024px)[content-end] minmax(.5rem, auto)[fullbleed-end];
  gap: 2rem 1rem;
  display: grid;
}

@media (width >= 600px) {
  main {
    grid-template: auto / [fullbleed-start] minmax(1rem, auto)[content-start] minmax(250px, 1024px)[content-end] minmax(1rem, auto)[fullbleed-end];
    gap: 4rem 2rem;
  }
}

main > :not(.fullbleed) {
  grid-column: content;
}

main > .fullbleed {
  grid-column: fullbleed;
  grid-template-columns: subgrid;
  display: grid !important;
}

main > .fullbleed > * {
  grid-column: content;
}

:root {
  --base: #fff;
  --text: #333;
}

@media (prefers-color-scheme: dark) {
  :root {
    --base: #333;
    --text: #fff;
  }
}

html {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light dark;
}

@media (prefers-color-scheme: dark) {
  html {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
  }
}

:root:has(#theme [value="☀️"]:checked) {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
  --base: #fff;
  --text: #333;
}

:root:has(#theme [value="🌑"]:checked) {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
  --base: #333;
  --text: #fff;
}

body {
  color: var(--text);
  fill: var(--text);
  background-color: var(--base);
}

#theme {
  display: none;
  position: absolute;
  inset: 1rem 1rem auto auto;
}

@media (width >= 600px) {
  #theme {
    display: block;
  }
}

#theme label {
  background: none;
}

#theme label:has(input:checked) {
  text-shadow: 0 0 .25em #0ff;
}

#theme label input {
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  @keyframes animated-gradient {
    0%, 100% {
      background-position: 0;
    }

    50% {
      background-position: 100%;
    }
  }

  .animation-breathe span {
    font-variation-settings: "MONO" 1, "wght" 300, "CASL" 0, "CRSV" 0;
    will-change: font-variation-settings;
    animation: 4s infinite breathe;
  }

  @keyframes breathe {
    0%, 100% {
      font-variation-settings: "MONO" 1, "wght" 300, "CASL" 0, "CRSV" 0;
    }

    25% {
      font-variation-settings: "MONO" 1, "wght" 800, "CASL" 0, "CRSV" 0;
    }

    50% {
      font-variation-settings: "MONO" 1, "wght" 800, "CASL" 1, "CRSV" 0;
    }

    75% {
      font-variation-settings: "MONO" 1, "wght" 300, "CASL" 1, "CRSV" 0;
    }
  }

  @property --a {
    syntax: "<angle>"; inherits: false; initial-value: 0deg;
  }

  @keyframes rotateBG {
    to {
      --a: 360deg;
    }
  }

  .badge {
    color: #fff;
    background: #000;
    overflow: clip;
  }

  .badge:after {
    --a: 0deg;
    content: "";
    background: radial-gradient(circle, #fff, transparent 30%), conic-gradient(from var(--a), #0698f2, #0ff, #0698f2);
    will-change: background;
    mix-blend-mode: color-burn;
    animation: 5s linear infinite rotateBG;
    position: absolute;
    inset: 0;
  }

  .badge svg.animated path {
    transition: stroke-dashoffset .25s linear;
  }

  .badge svg.animated.animate path, .badge svg.animated:hover path {
    stroke-dashoffset: 0;
  }

  .badge svg.animated.animate path#jezmckE, .badge svg.animated:hover path#jezmckE {
    transition-delay: .25s;
  }

  .badge svg.animated.animate path#jezmckZM, .badge svg.animated:hover path#jezmckZM {
    transition-duration: .5s;
    transition-delay: .5s;
  }

  .badge svg.animated.animate path#jezmckC, .badge svg.animated:hover path#jezmckC {
    transition-duration: .125s;
    transition-delay: 1s;
  }

  .badge svg.animated.animate path#jezmckK1, .badge svg.animated:hover path#jezmckK1 {
    transition-delay: 1.125s;
  }

  .badge svg.animated.animate path#jezmckK2, .badge svg.animated:hover path#jezmckK2 {
    transition-delay: 1.25s;
  }

  .badge svg.animated.animate path#jezmckU1, .badge svg.animated:hover path#jezmckU1 {
    transition-duration: 1.25s;
    transition-delay: .125s;
  }

  .badge svg.animated.animate path#jezmckU2, .badge svg.animated:hover path#jezmckU2 {
    transition-delay: 1.25s;
  }

  .badge svg .glow path {
    stroke: #fff8;
  }
}

.badge {
  position: relative;
}

.badge svg {
  position: absolute;
  inset: 0;
}

header, footer {
  color: #fff;
  text-align: center;
  background-color: #000;
  box-shadow: 0 0 5px #00000040;
}

header {
  z-index: 10;
  flex-direction: column;
  gap: .5rem;
  padding: 2rem 3rem;
  display: flex;
  position: sticky;
  top: 0;
}

header h1 {
  font-size: 2.5rem;
}

@media (prefers-color-scheme: dark) {
  header {
    background: #000;
  }

  header h1 {
    color: #0000;
    background: #fff url("../clouds-static.557f43b8.svg") 0 0 / contain;
    -webkit-background-clip: text;
    background-clip: text;
  }
}

@media (prefers-color-scheme: light) {
  header {
    background: #0698f2 url("../clouds-static.557f43b8.svg") center / cover no-repeat;
  }

  header h1 {
    text-shadow: 0 0 .125em #fff;
    background: none;
    background-clip: initial;
    color: #000;
  }
}

:root:has(#theme [value="☀️"]:checked) header {
  background: #0698f2 url("../clouds-static.557f43b8.svg") center / cover no-repeat;
}

:root:has(#theme [value="☀️"]:checked) header h1 {
  text-shadow: 0 0 .125em #fff;
  background: none;
  background-clip: initial;
  color: #000;
}

@media (width >= 600px) {
  header h1 {
    font-size: 3.5rem;
  }

  header h2 {
    font-size: 1.5rem;
  }
}

footer {
  padding: 1rem;
  font-size: small;
}

section {
  flex-direction: column;
  gap: 1rem;
  padding: 3rem;
  display: flex;
}

section.bg-primary {
  color: #fff;
  background-color: #0698f2;
}

section.bg-clouds {
  text-shadow: .0625em .0625em .0625em rgba(var(--base), .5);
  background: #0698f2 url("../clouds-static.557f43b8.svg") center / cover no-repeat;
  position: relative;
}

section.bg-clouds:before {
  z-index: -1;
  content: "";
  background: var(--base);
  opacity: .75;
  max-width: 1024px;
  margin: 0 auto;
  position: absolute;
  inset: 1rem;
  box-shadow: 0 0 5px #00000040;
}

section.bg-clouds-animated {
  background-image: url("../clouds.5949f093.svg");
}

section#intro {
  text-align: center;
  grid-template-areas: "badge"
                       "title"
                       "headshot"
                       "highlighted";
  place-items: center;
  gap: 1rem 2rem;
  padding: 2rem 0 0;
  display: grid;
}

@media (width >= 768px) {
  section#intro {
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-areas: "badge title headshot"
                         "badge highlighted headshot";
  }
}

@media (width >= 1024px) {
  section#intro {
    grid-template-columns: 1fr 4fr 1fr;
  }
}

@media (width >= 1280px) {
  section#intro {
    grid-template-columns: 1fr 5fr 1fr;
  }
}

section#intro .badge, section#intro .headshot {
  width: 100%;
  max-width: 300px;
  box-shadow: 0 0 5px #00000040;
}

section#intro .badge {
  aspect-ratio: 1;
  border-radius: 50%;
  grid-area: badge;
}

section#intro .headshot {
  aspect-ratio: 1;
  border-radius: 50%;
  grid-area: headshot;
  margin: 0;
  position: relative;
  overflow: clip;
}

section#intro .headshot img {
  border-radius: inherit;
  width: 100%;
}

section#intro .headshot:after {
  border-radius: inherit;
  content: "";
  pointer-events: none;
  margin: .5rem;
  position: absolute;
  inset: 0;
  box-shadow: .25rem .25rem #0ff;
}

section#intro p {
  text-wrap: balance;
  grid-area: title;
  place-self: end center;
  font-size: 1.5rem;
}

section#intro ul.highlighted {
  flex-direction: column;
  grid-area: highlighted;
  justify-content: start;
  place-self: start center;
  gap: .5rem;
  margin: 0;
  padding: 0;
  display: flex;
}

section#intro ul.highlighted li {
  text-align: start;
  text-wrap: pretty;
  align-items: center;
  gap: 1rem;
  display: flex;
}

section#intro ul.highlighted li svg {
  width: 1.25em;
}

section#skills {
  place-content: center;
  display: grid;
}

section#skills:before {
  z-index: 0;
}

section#skills > * {
  z-index: 1;
}

section#skills ul {
  text-align: center;
  text-wrap: balance;
}

section#skills ul li {
  color: #fff;
  text-align: center;
  background-color: #0698f2;
  border-radius: .25rem;
  margin-block: .125em;
  padding: .5rem 1rem;
  display: inline-block;
}

section.examples div.images {
  border-image: linear-gradient(175deg, #0000 0 40%, #0698f2 40.2% 41%, #0000 41.2% 45%, #0698f2 45.2%, #0000 55%) 0 fill /  / 0 100vw;
}

section.examples div.images:nth-child(2n) {
  border-image: linear-gradient(185deg, #0000 0 40%, #0698f2 40.2% 41%, #0000 41.2% 45%, #0698f2 45.2%, #0000 55%) 0 fill /  / 0 100vw;
}

.hidden {
  display: none;
}

a.button {
  color: #fff;
  white-space: nowrap;
  background: #0698f2;
  border-radius: .5rem;
  padding: .5rem;
  text-decoration: none;
  transition: scale .3s, box-shadow .6s;
  display: inline-block;
  box-shadow: 0 0 #0ff;
}

a.button:hover {
  scale: 1.1;
  box-shadow: 0 0 0 5px #0ff;
}

#promo {
  z-index: 9;
  text-align: center;
  backdrop-filter: blur(10px);
  border-radius: 999em;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  padding: 2rem 2.5rem;
  display: flex;
  position: sticky;
  top: 85px;
  box-shadow: 0 0 5px #00000040;
}

#promo:after {
  border-radius: inherit;
  content: "";
  pointer-events: none;
  margin: .5rem;
  position: absolute;
  inset: 0;
  box-shadow: .25rem .25rem #0ff;
}

@media (width >= 600px) {
  #promo {
    top: 105px;
  }
}

#promo:before {
  border-radius: 999em;
}

#promo p {
  text-wrap: balance;
  font-weight: 800;
}

#promo p:first-of-type {
  font-size: larger;
}

#promo p:last-of-type {
  font-size: smaller;
}

#promo .buttons {
  text-wrap: balance;
}

#promo .buttons a {
  place-content: center;
  width: 3em;
  height: 3em;
  margin: .1875em;
  display: inline-grid;
}

#promo .buttons svg {
  width: 100%;
  height: 100%;
}

hr {
  background-image: linear-gradient(to right, #0ff, #0698f2, #0ff);
  border: 0;
  width: 100%;
  height: 1px;
  margin-block: 2rem;
}

.images {
  grid-template: auto / repeat(auto-fit, minmax(240px, 1fr));
  place-items: center;
  gap: 1rem;
  margin-block: 1rem;
  display: grid;
  container-type: inline-size;
}

.images > * {
  aspect-ratio: 4 / 3;
  box-shadow: 0 0 5px #0000001a;
}

@container (490px <= width <= 740px) {
  .images > :nth-child(odd):last-child {
    grid-column: span 2;
  }
}

.images img {
  object-fit: cover;
}

.images img:after {
  border-radius: inherit;
  content: "";
  pointer-events: none;
  margin: .5rem;
  position: absolute;
  inset: 0;
  box-shadow: .25rem .25rem #0ff;
}
/*# sourceMappingURL=styles.css.map */
