@mixin box-shadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

@mixin zing-flick {
  &::after {
    margin: $spacing * 0.5;
    border-radius: inherit;
    position: absolute;
    content: "";
    inset: 0;
    box-shadow: ($spacing * 0.25) ($spacing * 0.25) 0 $color-pop;
    pointer-events: none;
  }
}
