@media (prefers-reduced-motion: no-preference) {
  @keyframes animated-gradient {
    0%, 100% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }

  .animation-breathe span {
    font-variation-settings: "MONO" 1, "wght" 300, "CASL" 0, "CRSV" 0;
    animation: breathe 4000ms ease infinite;
    will-change: font-variation-settings;
  }

  @keyframes breathe {
    0%, 100% {
      font-variation-settings: "MONO" 1, "wght" 300, "CASL" 0, "CRSV" 0;
    }

    25% {
      font-variation-settings: "MONO" 1, "wght" 800, "CASL" 0, "CRSV" 0;
    }

    50% {
      font-variation-settings: "MONO" 1, "wght" 800, "CASL" 1, "CRSV" 0;
    }

    75% {
      font-variation-settings: "MONO" 1, "wght" 300, "CASL" 1, "CRSV" 0;
    }
  }

  // @todo reinstate!
@property --a {
  syntax: '<angle>';
  inherits: false;
  initial-value: 0deg;
}

  @keyframes rotateBG {
    to {
      --a: 360deg;
    }
  }

  .badge {
    overflow: clip;
    background: #000000;
    color: #ffffff;

    &::after {
      --a: 0deg; /* needed for firefox to have a valid output */
      //border-radius: 50%;
      //overflow: clip;
      content: "";
      position: absolute;
      inset: 0;
      background: radial-gradient(circle, #ffffff, transparent 30%),
      conic-gradient(
          from var(--a),
          $color-primary,
          //#000000,
          $color-pop,
          //#000000,
          $color-primary
      );
      will-change: background;
      animation: rotateBG 5s linear infinite;
      mix-blend-mode: color-burn;
    }

    svg {
      &.animated {
        path {
          transition: stroke-dashoffset 250ms linear;
        }

        &.animate,
        &:hover {
          path {
            stroke-dashoffset: 0;

            &#jezmckE {
              transition-delay: 250ms;
            }

            &#jezmckZM {
              transition-delay: 500ms;
              transition-duration: 500ms;
            }

            &#jezmckC {
              transition-delay: 1000ms;
              transition-duration: 125ms;
            }

            &#jezmckK1 {
              transition-delay: 1125ms;
            }

            &#jezmckK2 {
              transition-delay: 1250ms;
            }

            &#jezmckU1 {
              transition-delay: 125ms;
              transition-duration: 1250ms;
            }

            &#jezmckU2 {
              transition-delay: 1250ms;
            }
          }
        }
      }

      .glow path {
        stroke: #ffffff88;
      }
    }
  }
}

.badge {
  position: relative;

  svg {
    position: absolute;
    inset: 0;
  }
}
